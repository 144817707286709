import {
  Box,
  ButtonProps,
  Circle,
  Divider,
  GridItem,
  HStack,
  Icon,
  Link,
  SimpleGrid,
  Tag,
  Text,
  useBoolean,
  VStack,
} from "@chakra-ui/react";
import { isNull, toLower } from "lodash";
import { ReactNode, useMemo, useState } from "react";
import {
  FaBullhorn,
  FaChevronDown,
  FaChevronUp,
  FaEnvelope,
  FaExclamationCircle,
  FaListAlt,
  FaTimesCircle,
} from "react-icons/fa";
import { useSelector } from "react-redux";
import {
  addSuffixForPlural,
  convertCase,
  isFulfilled,
  isLoading,
} from "../../../../common/helper/commonHelper";
import {
  MARKETING_ACTIVITY_TYPE,
  CampaignAdditionalData,
  FormAdditionalData,
  PersonMarketingActivitiesDetails,
  WebsiteActivityAdditionalData,
  PageVisitType,
  PROTOCOL,
} from "../../../../common/types/person";
import CenteredTextBox from "../../../../components/CenteredTextBox";
import IButton from "../../../../components/IButton";
import ITitle from "../../../../components/ITitle";
import Pagination from "../../../../components/Pagination";
import SpinnerContainer from "../../../../components/SpinnerContainer";
import { useAppDispatch } from "../../../../store";
import {
  getEmailSent,
  getSessionSummary,
  selectPerson,
  setMarketingActivityPage,
} from "../personDbSlice";
import sanitizeHtml from "sanitize-html";
import {
  FormatDate,
  formatDateString,
} from "../../../../components/DateTimeRangeFilter";
import ContentContainer from "../../../../components/v2/ContentContainer";
import { SubmissionSidebar } from "../../form/components/SubmissionSidebar";
import { FormSubmissionDetails } from "../../../../common/types/form";
import { getFormSubmissionDetails } from "../../form/formSlice";
import { getFormSubmissionDetailsApi } from "../../../../common/api/integrations/form";
import { format } from "date-fns";
import ISkeleton, { SKELETON_VARIANT } from "../../../../components/ISkeleton";

type DISPLAY_VALUE_TYPE = { display: string; value: string | ReactNode };

const UTM_PARAM_LIST = [
  "utm_campaign",
  "utm_term",
  "utm_medium",
  "utm_source",
  "utm_content",
];

function getUrlDetails(data: WebsiteActivityAdditionalData | PageVisitType) {
  return {
    domain: data.page_domain_path,
    fragment: data.page_url_fragment,
    scheme: data.page_url_scheme,
  };
}

function getSessionDate(date: string) {
  return formatDateString({ date });
}

function getEventTime(date: string) {
  return format(new Date(date), "p");
}

function calcSessionLength(sessionData: WebsiteActivityAdditionalData) {
  if (sessionData.end_time) {
    const dateInMilliSec =
      new Date(sessionData.end_time).getTime() -
      new Date(sessionData.start_time).getTime();
    const hours = Math.floor(dateInMilliSec / 36e5);
    const minutes = Math.floor((dateInMilliSec % 36e5) / 6e4);

    const hoursDisplay = hours
      ? `${hours} ${addSuffixForPlural("hour", hours)}`
      : "";

    const minutesDisplay = minutes
      ? `${minutes} ${addSuffixForPlural("minute", minutes)}`
      : "";
    return `${hoursDisplay} ${minutesDisplay}`;
  }
  return <Text color="gray.300">(Ongoing session)</Text>;
}

function getSessionInfo(
  sessionData: WebsiteActivityAdditionalData
): DISPLAY_VALUE_TYPE[] {
  const pageDetails = getUrlDetails(sessionData);

  const referrerDetails = {
    domain: sessionData.referrer_domain_path,
    fragment: sessionData.referrer_url_fragment,
    scheme: sessionData.referrer_url_scheme,
  };

  return [
    {
      display: "Intial page URL",
      value: !!sessionData.page_domain_path && (
        <InlineTextLink {...pageDetails} />
      ),
    },
    {
      display: "Source URL (referrer)",
      value: !!sessionData.referrer_domain_path && (
        <InlineTextLink {...referrerDetails} />
      ),
    },
    {
      display: "Session length",
      value: !!sessionData.start_time && calcSessionLength(sessionData),
    },
    { display: "Platform", value: sessionData.user_agent_os },
    {
      display: "IP address",
      value: sessionData.client_ip,
    },
  ];
}

function getUtmAndCustomInfo(
  data: WebsiteActivityAdditionalData
): DISPLAY_VALUE_TYPE[] {
  const utmInfo = UTM_PARAM_LIST.map((param) => {
    return {
      display: convertCase(param).replace("Utm", "UTM"),
      value: data[param as keyof typeof data],
    };
  });

  const customInfo = Object.entries(data.custom_parameters).map(
    ([display, value]) => {
      return { display, value };
    }
  );

  return [...utmInfo, ...customInfo];
}

const EVENTS_NAME: {
  [key: string]: { title: string; icon?: ReactNode; color?: string };
} = {
  clicked: { title: "Email link clicked" },
  opened: { title: "Email opened" },
  delivered: { title: "Email delivered" },
  processed: { title: "Email processed" },
  dropped: {
    title: "Email dropped",
    icon: <FaExclamationCircle />,
    color: "brand.orange",
  },
  unsubscribed: {
    title: "Unsubscribed",
    icon: <FaTimesCircle />,
    color: "brand.red",
  },
  sent: { title: "Email sent" },
  form_submitted: { title: "Form submitted" },
  session: { title: "Website visit session" },
};

const SENT_EVENT_TYPE = "Sent";
const CLICK_EVENT_TYPE = "Clicked";

function EmailRender({ emailContent }: { emailContent: string }) {
  function htmlDecode(input: string) {
    var e = document.createElement("div");
    e.innerHTML = input;
    return e.childNodes.length
      ? sanitizeHtml(input, {
          allowedTags: sanitizeHtml.defaults.allowedTags.concat(["img"]),
        })
      : "";
  }

  return (
    <Box px={3} w="100%">
      <ITitle title="Email message" fontWeight="500" color="gray.400" mb={1} />
      <Box
        minW="100%"
        bg="gray.100"
        p={4}
        wordBreak="break-all"
        borderRadius="sm"
      >
        <Box dangerouslySetInnerHTML={{ __html: htmlDecode(emailContent) }} />
      </Box>
    </Box>
  );
}

function GridContent({
  title,
  dataList,
}: {
  title: string;
  dataList: DISPLAY_VALUE_TYPE[];
}) {
  const isNotNull = dataList.some((item) => !isNull(item.value));

  return (
    <Box p={4} hidden={!isNotNull}>
      <ITitle title={title} fontWeight={600} pb={4} capitalize={false} />
      <SimpleGrid columns={3} spacingX="60px" spacingY={5} w="80%">
        {dataList.map((data) => {
          return (
            !!data.value && (
              <GridItem>
                <VStack align="left" spacing={2}>
                  <Text fontSize="12px" color="text.50">
                    {data.display}
                  </Text>
                  <Box overflowWrap="break-word">{data.value}</Box>
                </VStack>
              </GridItem>
            )
          );
        })}
      </SimpleGrid>
    </Box>
  );
}

function InlineTextLink({
  text,
  domain,
  protocol = PROTOCOL.HTTPS,
  fragment = "",
}: {
  text?: string;
  domain: string;
  protocol?: PROTOCOL;
  fragment?: string;
}) {
  const addFragment = `${fragment ? `#${fragment}` : ""}`;
  const urlToDisplay = `${domain}${addFragment}`;
  const urlToNavigate = `${protocol}://${urlToDisplay}`;

  return (
    <Text>
      {text ?? ""}{" "}
      <Link href={urlToNavigate} color="brandBlue.500" target="_blank">
        {urlToDisplay}
      </Link>
    </Text>
  );
}

function ActivityCard({
  event,
  data,
  buttonProps,
  children,
}: {
  event: {
    title: string;
    icon?: ReactNode;
    color?: string;
  };
  data: PersonMarketingActivitiesDetails;
  buttonProps?: ButtonProps;
  children: ReactNode;
}) {
  const utcOffsettedDate = `${data.timestamp}Z`;
  return (
    <>
      <HStack
        justifyContent="space-between"
        w="100%"
        p={3}
        bg="grayV2.100"
        fontWeight="600"
        borderBottom="1px solid"
        borderColor="gray.200"
      >
        <HStack color={event?.color || "brand.black"} alignItems="center">
          {event?.icon}
          <ITitle title={event?.title || data.event} mt="2px !important" />
        </HStack>
        <FormatDate date={utcOffsettedDate} showTime />
      </HStack>
      <HStack justifyContent="space-between" p={3}>
        {children}
        <IButton
          size="sm"
          fontSize="13px"
          name="show-hide-toggle"
          {...buttonProps}
        />
      </HStack>
    </>
  );
}

function TrackedWebsiteDetailsFrame({
  data,
}: {
  data: WebsiteActivityAdditionalData;
}) {
  return (
    <>
      <GridContent
        title="Session information"
        dataList={getSessionInfo(data)}
      />
      <Divider />
      <GridContent
        title="UTM and Custom parameters"
        dataList={getUtmAndCustomInfo(data)}
      />
    </>
  );
}

function SessionActivityItem({
  index,
  isLast,
  event,
}: {
  index: number;
  isLast: boolean;
  event: PageVisitType;
}) {
  const pageUrlDetails = getUrlDetails(event);
  return (
    <VStack
      key={event.event_time}
      align="left"
      spacing={0}
      position="relative"
      minH="50px"
    >
      {!isLast && (
        <Box
          borderLeft="2px dashed"
          h="calc(100% - 20px)"
          position="absolute"
          bottom={0}
          left="9px"
          borderColor="gray.300"
        />
      )}
      <HStack alignItems="flex-start">
        <Circle
          bg="gray.200"
          size="20px"
          border="1px solid"
          borderColor="gray.300"
          mr={3}
          color="gray.500"
          fontSize={12}
        >
          {index + 1}
        </Circle>
        <Text color="text.50" fontSize="12px" pr={3} pt="2px">
          {getEventTime(event.event_time)}
        </Text>
        <VStack align="left" spacing={0}>
          <InlineTextLink text="User visited" {...pageUrlDetails} />
          <Text color="oneOffs.subtitle" fontSize="14px" pr={3} pt={1}>
            {event.page_title ?? ""}
          </Text>
        </VStack>
      </HStack>
    </VStack>
  );
}

function SessionActivityContent({
  group,
  index,
}: {
  group: PageVisitType[];
  index: number;
}) {
  return (
    <VStack
      spacing={0}
      align="left"
      m={2}
      mb={5}
      ml={8}
      key={index}
      maxHeight="300px"
      overflowY="auto"
    >
      {group.map((event, index) => (
        <SessionActivityItem
          index={index}
          isLast={index + 1 === group.length}
          event={event}
        />
      ))}
    </VStack>
  );
}

function SessionActivityFrame({ sessionId }: { sessionId: string }) {
  const { sessionSummaryList } = useSelector(selectPerson);

  const currentSession = sessionSummaryList[sessionId].data;
  const loading = isLoading(sessionSummaryList[sessionId].loading);

  const groupedByDateList = useMemo(() => {
    const groupedBydate = currentSession?.reduce(
      (visitsList: { [key: string]: PageVisitType[] }, currVisit) => {
        const date = getSessionDate(currVisit.event_time);
        visitsList[date] = visitsList[date] ?? [];
        visitsList[date].push(currVisit);
        return visitsList;
      },
      {}
    );
    return Object.values(groupedBydate ?? {});
  }, [currentSession]);

  return (
    <>
      <ITitle
        title="Website session activity"
        fontWeight={600}
        m={4}
        mb={2}
        capitalize={false}
        hidden={!currentSession?.length}
      />
      <ISkeleton
        variant={SKELETON_VARIANT.TIMELINE}
        isLoaded={loading}
        align="left"
        spacing={4}
        m={2}
        ml={8}
        mb={5}
      >
        {groupedByDateList.map((group, index) => {
          return (
            <>
              <Text color="text.50" fontSize="12px" mt={3} mb={3} ml={4}>
                {getSessionDate(group[0].event_time)}
              </Text>
              <SessionActivityContent group={group} index={index} />
            </>
          );
        })}
      </ISkeleton>
    </>
  );
}

function WebsiteActivityCard({
  data,
}: {
  data: PersonMarketingActivitiesDetails;
}) {
  const [expand, setExpand] = useState(false);
  const additionalWebsiteInfo =
    data.additional_data as WebsiteActivityAdditionalData;

  const dispatch = useAppDispatch();

  const pageDetails = getUrlDetails(additionalWebsiteInfo);

  const {
    personDetails: { data: personData },
  } = useSelector(selectPerson);

  const personId = personData?.[0]?.id;

  function showDetails() {
    !expand && dispatch(getSessionSummary({ personId, sessionId: data.id }));
    setExpand(!expand);
  }

  return (
    <Box
      borderRadius="md"
      overflow="hidden"
      w="100%"
      fontSize="14px"
      border="1px solid"
      borderColor="gray.200"
      margin="10px !important"
    >
      <ActivityCard
        event={EVENTS_NAME["session"]}
        data={data}
        buttonProps={{
          onClick: showDetails,
          rightIcon: expand ? <FaChevronUp /> : <FaChevronDown />,
          children: `${expand ? "Hide" : "Show"} details`,
        }}
      >
        <InlineTextLink text="Browsed through" {...pageDetails} />
      </ActivityCard>
      <Divider />
      {expand && (
        <>
          <TrackedWebsiteDetailsFrame data={additionalWebsiteInfo} />
          <Divider />
          <SessionActivityFrame sessionId={data.id} />
        </>
      )}
    </Box>
  );
}

function FormActivityCard({
  data,
}: {
  data: PersonMarketingActivitiesDetails;
}) {
  const dispatch = useAppDispatch();

  const [selectedSubmission, setSelectedSubmission] =
    useState<FormSubmissionDetails | null>(null);
  const [isFetchingSubmissionDetails, setIsFetchingDetails] = useBoolean(false);

  const event = EVENTS_NAME[toLower(data.event)];

  async function openSubmissionSidebar() {
    setIsFetchingDetails.on();
    const res = await dispatch(
      getFormSubmissionDetails(
        (data.additional_data as FormAdditionalData).submission_id
      )
    );
    setIsFetchingDetails.off();
    if (isFulfilled(res.meta.requestStatus)) {
      const payload = res.payload as Awaited<
        ReturnType<typeof getFormSubmissionDetailsApi>
      >;
      setSelectedSubmission(payload.submission);
    }
  }

  return (
    <Box
      borderRadius="md"
      overflow="hidden"
      w="100%"
      fontSize="14px"
      border="1px solid"
      borderColor="gray.200"
      margin="10px !important"
    >
      <ActivityCard
        event={event}
        data={data}
        buttonProps={{
          isLoading: isFetchingSubmissionDetails,
          onClick: openSubmissionSidebar,
          children: "View submission",
        }}
      >
        <HStack>
          <Tag>
            <Icon as={FaListAlt} color="brand.blue" />{" "}
            <Text ml={2}>{data.name}</Text>
          </Tag>
        </HStack>
      </ActivityCard>
      <SubmissionSidebar
        formId={data.id}
        hideHyperLink
        selectedSubmission={selectedSubmission}
        onClose={() => setSelectedSubmission(null)}
      />
    </Box>
  );
}

function CampaignActivityCard({
  data,
  personId,
}: {
  data: PersonMarketingActivitiesDetails;
  personId: string;
}) {
  const [expand, setExpand] = useState(false);
  const dispatch = useAppDispatch();
  const { emailSent } = useSelector(selectPerson);

  const event = EVENTS_NAME[toLower(data.event)];
  const emailData =
    emailSent.data[
      (data.additional_data as CampaignAdditionalData)?.correlation_id
    ];

  function showEmail() {
    dispatch(
      getEmailSent({
        personId,
        correlationId: (data.additional_data as CampaignAdditionalData)
          ?.correlation_id,
      })
    );
  }

  function showDetails() {
    setExpand(!expand);
    !expand && data.event === SENT_EVENT_TYPE && !emailData && showEmail();
  }

  return (
    <Box
      borderRadius="md"
      overflow="hidden"
      w="100%"
      fontSize="14px"
      border="1px solid"
      borderColor="gray.200"
      margin="10px !important"
    >
      <ActivityCard
        event={event}
        data={data}
        buttonProps={{
          onClick: showDetails,
          rightIcon: expand ? <FaChevronUp /> : <FaChevronDown />,
          children: `${expand ? "Hide" : "Show"} details`,
          hidden: !(data.context || data.event === SENT_EVENT_TYPE),
        }}
      >
        <HStack>
          <Tag>
            <Icon as={FaEnvelope} color="brand.blue" />{" "}
            <Text ml={2}>
              {(data.additional_data as CampaignAdditionalData)?.template?.name}
            </Text>
          </Tag>
          <Text>from</Text>
          <Tag>
            <Icon as={FaBullhorn} color="brand.blue" />{" "}
            <Text ml={2}>{data.name}</Text>
          </Tag>
        </HStack>
      </ActivityCard>
      <Divider />
      {expand && (
        <VStack alignItems="flex-start" pb={5} py={3} spacing={3} w="100%">
          {data.context && (
            <Box px={3}>
              <ITitle
                title={`${data.event === CLICK_EVENT_TYPE ? "Link" : "Remark"}`}
                fontWeight="500"
                color="gray.400"
                mb={1}
              />
              {data.event === CLICK_EVENT_TYPE ? (
                <Link
                  href={data.context}
                  target="_blank"
                  maxWidth="100%"
                  wordBreak="break-all"
                >
                  {data.context}
                </Link>
              ) : (
                <Text color="brand.black"> {data.context} </Text>
              )}
            </Box>
          )}
          {data.event === SENT_EVENT_TYPE && (
            <SpinnerContainer
              loading={!emailData && isLoading(emailSent.loading)}
              width="100%"
            >
              {emailData && <EmailRender emailContent={emailData.content} />}
            </SpinnerContainer>
          )}
        </VStack>
      )}
    </Box>
  );
}

export default function PersonMarketingActivities({
  personId,
}: {
  personId: string;
}) {
  const dispatch = useAppDispatch();
  const { personMarketingActivity } = useSelector(selectPerson);

  function selectActivityCardType(
    activity: PersonMarketingActivitiesDetails,
    index: number
  ) {
    switch (activity.activity_type) {
      case MARKETING_ACTIVITY_TYPE.FORM:
        return <FormActivityCard data={activity} key={index} />;
      case MARKETING_ACTIVITY_TYPE.CAMPAIGN:
        return (
          <CampaignActivityCard
            data={activity}
            key={index}
            personId={personId}
          />
        );
      case MARKETING_ACTIVITY_TYPE.WEBSITE_ACTIVITY:
        return <WebsiteActivityCard data={activity} />;
    }
  }

  return (
    <SpinnerContainer
      loading={personMarketingActivity.fetchingList}
      height="500px"
    >
      {personMarketingActivity.totalPageCount &&
      personMarketingActivity.list?.length ? (
        <ContentContainer flexDir="column">
          <VStack w="100%">
            {personMarketingActivity.list.map((activity, index) =>
              selectActivityCardType(activity, index)
            )}

            <Pagination
              currentPage={personMarketingActivity.currentPageNo}
              onPageChange={(pageNo: number) =>
                dispatch(setMarketingActivityPage(pageNo))
              }
              pageCount={personMarketingActivity.totalPageCount}
            />
          </VStack>
        </ContentContainer>
      ) : (
        <CenteredTextBox color="gray.400" message="No marketing activities" />
      )}
    </SpinnerContainer>
  );
}
